body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* --------------------------------------------------------------------------------- */
/*--------------------------- Navbar Style --------------------------------- */

.myNavbar {
  background-color: #2C3E50;
}

.collapse li a{
  font-size: 16px;
}
.myActive {
  background-color: #1ABC9C;
  border-radius: 7px;
}
nav{
  transition: all 0.5s;
}
/* --------------------------------------------------------------------------------- */
/*--------------------------- Footer Style --------------------------------- */

.footer2{
  background-color: #1A252F;
}

.footer1{
  background-color: #2C3E50;
}

.divIcons{
  display: flex;
  justify-content: center;
  align-items: center;
}

.divIcons i{

  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 1px;
  display: flex;
  justify-content: center;
  align-items: center; 
}
/* --------------------------------------------------------------------------------- */
/*--------------------------- Home Style --------------------------------- */
.home{
  background-color: #1ABC9C;
}

.home img{
  width: 250px;
}

.shape{
  width: 90px;
  height: 4px;
}
/* --------------------------------------------------------------------------------- */
/*--------------------------- About Style --------------------------------- */
.about{
  background-color: #1ABC9C;
}
/* --------------------------------------------------------------------------------- */
/*--------------------------- Portfolio Style --------------------------------- */
.portfolio{
  background-color:white;
}
.portoTitle{
  color: #2C3E50;
}
.portoShape{
  background-color: #2C3E50;
}
.layer{
  background-color: #1abc9cde ;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display:flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s;
  cursor: pointer;
}
.item:hover .layer{
  opacity: 1;
}
.fa-plus{
  font-size: 100px;
  color: white;
}
/* --------------------------------------------------------------------------------- */
/*--------------------------- Contact Style --------------------------------- */
.contact{
  background-color:white;
}
/* -----------------------------------THE END ---------------------------------------------- */


/* Goz2 l height bt3 l navbar */

.myMargin{
  margin-top: 70px;  
} 

/* dah margin b kymt l hieght bt3 l nav bar */